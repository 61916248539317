@use "@wojo/ui/mixins" as *;

.container {
    margin-top: var(--g-spacing-lg);
    margin-bottom: var(--g-spacing-xl);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-xxl);
    }
}

.markdown {
    padding-bottom: var(--g-spacing-lg);
    ul {
        padding-left: var(--g-spacing-md);
    }
}

.card {
    padding: 0;
    overflow: hidden;
    display: grid;
    align-content: flex-start;
}

.card-image {
    aspect-ratio: 3/2;
    max-width: 100%;
    display: flex;
    justify-content: center;
    img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
}

.card-content-wrapper {
    display: flex;
    flex-direction: column;
    margin: var(--g-spacing-md);
    align-items: flex-start;
    text-align: left;
}
