@use "@wojo/ui/mixins" as *;

.modalDialog {
    padding-top: 0;
}

.header {
    display: flex;
    justify-content: flex-end;
    background-color: var(--g-color-white);
    padding-top: var(--g-spacing-lg);
    padding-left: var(--g-spacing-lg);
    padding-right: var(--g-spacing-lg);
    padding-bottom: var(--g-spacing-md);
}

.close {
    padding-left: var(--g-spacing-md);
}

.modal-body-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
        "info"
        "media";
    padding-left: unset;
    padding-right: unset;
    padding-bottom: unset;

    .info-content {
        grid-area: info;
    }

    .media {
        grid-area: media;
    }

    @include breakpoint-lg {
        grid-template-columns: repeat(2, 1fr);
        column-gap: var(--g-spacing-xl);
        grid-template-areas: "media info";
        margin: auto;
    }
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint-lg {
        padding-bottom: var(--g-spacing-md);
        border-bottom: var(--g-border-width-xs) solid
            var(--s-color-separator-standard);
    }
}

.desktop-only {
    display: none;
    @include breakpoint-lg {
        display: flex;
    }
}

@include breakpoint-lg {
    .mobile-only {
        display: none;
    }
}

.caption {
    margin-top: var(--g-spacing-sm);
    margin-bottom: var(--g-spacing-md);
}

.footer {
    position: sticky;
    width: 100%;
    box-sizing: border-box;
    background: var(--g-color-white);
    bottom: 0;
    z-index: 1;

    @include breakpoint-lg {
        display: none;
    }
}
