@use "@wojo/ui/mixins" as *;

.anchor-nav {
    min-height: 60px;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding-left: 0;

        li {
            margin-right: var(--g-spacing-md);
            padding-bottom: 0;
            white-space: nowrap;

            @include breakpoint-md {
                margin-right: var(--g-spacing-xl);
            }

            .anchor {
                font-family: var(--g-font-family-primary);
                font-size: var(--g-font-size-400);
                color: var(--s-color-tab-foreground-default);
                text-decoration: none;
                padding-bottom: calc(var(--g-spacing-md) + 3px);
                display: block;

                &:hover {
                    color: var(--s-color-tab-foreground-active);
                }

                &:focus-visible {
                    border-bottom: 3px solid var(--s-color-tab-border-focus);
                    padding-bottom: var(--g-spacing-md);
                    color: var(--s-color-tab-foreground-active);
                    outline: none;
                }

                &:global(.active-anchor) {
                    border-bottom: 3px solid var(--s-color-tab-border-selected);
                    padding-bottom: var(--g-spacing-md);
                    color: var(--s-color-tab-foreground-selected);

                    &:focus-visible {
                        border-bottom: 3px solid var(--s-color-tab-border-focus);
                        padding-bottom: var(--g-spacing-md);
                        color: var(--s-color-tab-foreground-active);
                        outline: none;
                    }
                }
            }
        }
    }

    .desktop-only {
        display: none;
        @include breakpoint-lg {
            display: flex;
        }
    }
    @include breakpoint-lg {
        .mobile-only {
            display: none;
        }
    }
}

.anchor-nav-container {
    overflow-x: auto;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--s-color-surface-secondary);
    box-shadow: var(--s-shadow-down);
    clip-path: inset(0px -10px -10px -10px);
    outline: 1px solid transparent;
}
