@use "../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
    }

    .wistia-player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        :global(.w-video-wrapper) {
            // Wistia puts this in the style attribute hence the !important
            background: transparent !important;
        }

        // HACK: Unlike other browsers, Firefox brings focus to the video element
        // in the Wistia player. However, this focus is invisible. This is
        // arguably a Wistia bug but not addressing it would make us non-WCAG
        // conformant (Success Criterion 2.4.7 Focus Visible) thus this hack
        :global(.w-video-wrapper:focus-within::after) {
            content: "";
            display: block;
            height: 100%;
            left: 0;
            outline-offset: calc(var(--g-border-width-md) * -1);
            outline: var(--g-border-width-md) solid var(--g-color-white);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }

        // HACK: Wistia replaces `outline` with `box-shadow` to indicate focus
        // but box-shadow isn't visible in high contrast mode. These styles
        // force the outline to be visible in high contrast mode.
        // WCAG Success Criterion 2.4.7 Focus Visible
        input:focus-visible + label,
        :global(.w-slider-wrapper),
        :global(.w-focus-outline),
        :global(.w-vulcan-v2-button):focus-visible {
            outline: 2px solid var(--g-color-white) !important;
            outline-offset: -2px;

            @media (forced-colors: active) {
                outline-color: Highlight !important;
            }
        }

        // HACK: Wistia doesn't apply `fitStrategy: "cover" to video thumbnails
        // so we have to force the video thumbnail to cover ourselves
        [data-handle="videoThumbnail"] video {
            object-fit: cover !important;
        }

        video {
            // The Wistia SDK only permits a single fitStrategy, but for this purpose
            // we want the inline video to cover the parent "collage" space
            // while the full screen video letterboxes to its intrinsic aspect ratio.
            object-fit: cover;
        }
    }

    .loading-wrapper {
        background: var(--s-color-surface-secondary);
        height: 100%;
        position: relative;
        overflow: hidden;
        background: var(--g-color-white);
        z-index: -1;
    }

    .loading-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .main-button {
        --button-height: var(--g-size-md);
        --button-width: var(--g-size-md);

        align-items: center;
        background: var(--g-color-white);
        border-radius: 100%;
        border: none;
        color: var(--g-color-ink-black);
        cursor: pointer;
        display: flex;
        box-shadow: (var(--s-shadow-standard-default));
        height: var(--button-height);
        justify-content: center;
        left: calc(50% - calc(var(--button-width) / 2));
        position: absolute;
        top: calc(50% - calc(var(--button-height) / 2));
        transition: none var(--g-duration-xshort) ease;
        transition-property: color, filter;
        width: var(--button-width);
        z-index: 1;

        &--hidden {
            display: none;
        }

        @include for-tablet-up {
            --button-height: var(--g-size-lg);
            --button-width: var(--g-size-lg);
        }

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }

        @media (pointer: coarse) {
            &.started {
                display: none;
            }
        }

        &.playing:not(:focus-visible) {
            border: 0;
            clip: rect(0, 0, 0, 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        &:hover {
            color: var(--g-color-primary-500);
        }

        &:focus-visible {
            color: var(--g-color-primary-500);
            box-shadow: (var(--s-shadow-standard-active));
            outline-color: var(--outline-color-focus);
            outline-offset: calc(var(--g-spacing-xs) * -1);
            outline-style: solid;
            outline-width: var(--g-border-width-sm);
            transition: none;
        }

        &:active {
            transform: scale(0.96);
        }

        svg {
            height: 24px;
            width: 24px;

            @include for-tablet-up {
                height: 30px;
                width: 30px;
            }
        }
    }

    .utility-button {
        align-items: center;
        background: var(--g-color-black);
        border-radius: 100%;
        border: none;
        bottom: var(--g-spacing-sm);
        color: var(--g-color-white);
        cursor: pointer;
        display: flex;
        height: 36px;
        justify-content: center;
        opacity: 0.6;
        position: absolute;
        right: var(--g-spacing-sm);
        transition: none var(--g-duration-xshort) ease;
        transition-property: opacity, transform;
        width: 36px;
        z-index: 1;

        &--left {
            right: auto;
            left: var(--g-spacing-sm);
        }

        &--right {
            right: var(--g-spacing-sm);
            left: auto;
        }

        &--hidden {
            display: none;
        }

        &:hover {
            opacity: 1;
        }

        &:active {
            transform: scale(0.96);
        }

        &:focus-visible {
            opacity: 1;
            outline: 2px solid var(--g-color-white);
            outline-offset: var(--g-spacing-xxs);
        }

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }
}
