@use "../../../styles/mixins" as *;
@include layer(ui) {
    .nav-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: var(--g-spacing-md) 0 0;
    }

    .text {
        display: flex;
        justify-content: center;
        padding: 0px var(--g-spacing-lg);
        min-width: 90px;
        font-family: var(--g-font-family-primary);
        font-weight: var(--g-font-weight-medium);
        font-size: var(--g-font-size-400);
        line-height: var(--g-line-height-400);
        color: var(--s-color-text-primary);
    }
}
