@use "../../../styles/mixins" as *;
@include layer(ui) {
    .viewport {
        overflow: hidden;
    }

    .track {
        display: grid;
        grid-template-columns: repeat(var(--nb-items), 100%);
        padding: 0px;
    }

    .slide {
        display: grid;
        place-items: center;
        grid-template-rows: auto max-content;
        width: 100%;
        margin-block-start: 0px;
        margin-block-end: 0px;
        margin-inline-start: 0px;
        margin-inline-end: 0px;

        > img {
            object-position: center center;
            object-fit: contain;

            max-height: 400px;
            max-width: 100%;

            @include for-tablet-up {
                max-height: 550px;
            }
        }

        .text {
            margin: var(--g-spacing-md) 0 0;
            text-align: center;
        }
    }
}
