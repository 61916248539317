@use "@wojo/ui/mixins" as *;

.summary-info-wrapper {
    display: grid;
    grid-template-columns: auto;

    column-gap: var(--g-spacing-xl);
    @include breakpoint-xs {
        justify-content: stretch;
    }
    @include breakpoint-md {
        display: flex;
        justify-content: flex-start;
    }
}

.summary-info-detail-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    gap: var(--g-spacing-xs);
    @include breakpoint-xs {
        border-bottom: 1px solid var(--s-color-separator-standard);
        width: 100%;
        padding: var(--g-spacing-sm) 0;
    }
    @include breakpoint-md {
        padding: 0;
        width: auto;
        border-bottom: none;
        grid-template-columns: auto;
    }
    &--class-level {
        @include breakpoint-xl {
            max-width: 150px;
        }
    }
}

.summary-info-detail {
    @include breakpoint-xs {
        justify-self: end;
    }
    @include breakpoint-md {
        justify-self: start;
    }

    &--class-level {
        &::after {
            content: ",";
            margin-right: var(--g-spacing-sm);
        }
        &:last-child::after {
            content: "";
            margin-right: 0;
        }
    }
}

.summary-icon {
    display: flex;
}
