@use "../../../styles/mixins" as *;
@include layer(ui) {
    .wrapper {
        padding: 0px var(--g-spacing-lg);

        @include for-tablet-up {
            padding: 0px var(--g-spacing-xl);
        }
    }
}
