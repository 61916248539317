@use "@wojo/ui/mixins" as *;

.info {
    @include breakpoint-lg {
        margin-top: var(--g-spacing-lg);
    }
}

.collapsible {
    padding-top: var(--g-spacing-md);
    padding-bottom: var(--g-spacing-md);
    padding-left: var(--g-spacing-sm);

    svg {
        padding-right: var(--g-spacing-sm);
    }
}
