@use "@wojo/ui/mixins" as *;

.media {
    margin-top: var(--g-spacing-lg);

    @include breakpoint-lg {
        margin-top: 0;
        height: calc(100vh - var(--modal-header-height) - var(--g-spacing-lg));
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: var(--g-spacing-xs);
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: var(--s-color-neutral-background);
            border-radius: var(--g-size-xs);
            border: transparent;
        }
    }
}

.caption {
    margin-top: var(--g-spacing-sm);
    margin-bottom: var(--g-spacing-md);
}

.override-figure {
    margin-block-start: unset;
    margin-block-end: unset;
    margin-inline-start: unset;
    margin-inline-end: unset;
    picture {
        display: flex;
    }
}
