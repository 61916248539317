@use "../../../styles/mixins" as *;
@include layer(ui) {
    .thumbs {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: var(--g-spacing-md);
    }

    .thumbs-viewport {
        --img-size: 60px;
        --outline-offset: calc(var(--g-border-width-sm) + var(--g-spacing-xxs));
        --focused-img-size: calc(
            var(--img-size) + calc(2 * var(--outline-offset))
        );

        height: var(--focused-img-size);
        overflow: hidden;
        padding: 0 var(--outline-offset);
        margin: calc(-1 * var(--outline-offset)) 0;

        @include for-tablet-up {
            --img-size: 80px;
        }
    }

    .thumbs-track {
        align-items: center;
        display: flex;
        gap: 16px;
        height: var(--focused-img-size);
    }

    .thumb {
        background: transparent;
        border: none;
        padding: 0;
        cursor: pointer;

        &:focus-visible {
            outline: solid var(--g-border-width-sm)
                var(--s-color-brand-primary-standard);
            outline-offset: var(--g-spacing-xxs);
        }

        .img-wrapper,
        img {
            width: var(--img-size);
            height: var(--img-size);
        }

        .img-wrapper {
            align-items: center;
            display: flex;
            overflow: hidden;
        }

        img {
            object-position: center;
            object-fit: cover;
            transition: transform var(--g-duration-xshort) ease;
        }

        &[aria-selected="true"] {
            img {
                transform: scale(0.85);
            }
        }

        &:hover {
            opacity: var(--g-opacity-moderate);
        }
    }
}
