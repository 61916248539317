.socials {
    margin-top: var(--g-spacing-lg);
    display: flex;
    gap: var(--g-spacing-md);
    justify-content: center;
}

.icon {
    box-sizing: border-box;
}
.social {
    display: flex;
    align-items: center;
    gap: var(--g-spacing-md);

    &:focus-visible {
        outline: none;
        .icon {
            transition: none;
            outline-style: solid;
            outline-offset: var(--g-spacing-xxs);
            outline-color: var(--outline-color-focus);
            outline-width: var(--g-border-width-sm);
            background-color: var(--background-color-active);
            border-color: var(--border-color-active);
            color: var(--icon-color-active);
        }
    }

    &:active {
        .icon {
            transform: scale(0.96);
        }
    }
}
