@use "@wojo/ui/mixins" as *;

.video-container {
    height: 327px;
    @include breakpoint-md {
        height: 650px;
    }
    width: 100%;
    max-width: 768px;
}
.image-container {
    max-height: 327px;
    width: 100%;
    object-fit: contain;
    @include breakpoint-md {
        max-height: 650px;
    }
}

.thumbnail-container {
    max-height: 327px;
    @include breakpoint-md {
        max-height: 650px;
    }
}
